import { NotasfiscaisService } from './../notasfiscais.service';
import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import 'moment/locale/pt-br';


@Component({
  selector: 'app-notasfiscais',
  templateUrl: './notasfiscais.component.html',
  styleUrls: ['./notasfiscais.component.css']
})
export class NotasfiscaisComponent implements OnInit {

  notasFiscaisProc;
  notasFiscaisConc;

  timerNotas;

  notaOpenMSG:any;
  notaOpen:any;
  notaOpenTimerATU;

  newStatus;

  newStatus2;


  constructor(public notasSrv:NotasfiscaisService) { 

      this.buscarNotas();

  }
  executando = false;

  buscarNotas(){
    if(this.executando)
      return;

    this.executando = true;
    this.notasSrv.getNotas().subscribe((data: Array<any>) => {
      // console.log("===>", data);
      this.notasFiscaisProc = data.reduce((ret, vl) => { 
        let has = ret.find(x => x.sistema === vl.sistema);
        if (!has) {
          ret.push({
            sistema: vl.sistema,
            lista: [{ ...vl, data: moment(vl.data).format("DD/MM/YYYY") }]
          })
        } else {
          has.lista.push({ ...vl, data: moment(vl.data).format("DD/MM/YYYY") });
        }
        return ret;
      },[]);
      if(this.notaOpen!=null){
        let consulta = data.filter(x=>x.cod_nfe == this.notaOpen.cod_nfe)[0];
        if(consulta!=null){
          this.notaOpen = consulta;
          this.notaOpen.data = moment(this.notaOpen.data).format("DD/MM/YYYY")
        }
      }
      this.executando = false;
      this.delay(5000).then(any=>{
          this.buscarNotas();
      });
      //this.notasFiscaisConc = data.filter(x=>x.situacao=="CONCLUIDO");
    })
  }
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(()=>resolve(), ms)).then();
  }

  nfExecutarNovamente(){
    if(confirm("Tem certeza? Certeza?\nC E R T E Z A ? ?")){      
      this.notasSrv.postNotas(this.notaOpen.cod_nfe, "clear","", this.notaOpen.sistema).subscribe((data:any)=>{        
          this.notaOpenMSG = data.Status + " - " + data.Mensagem;        
      });
    }
  }
  nfeNovoNumero(){
    if(confirm("Tem certeza? Certeza?\nC E R T E Z A ? ?")){      
      this.notasSrv.postNotas(this.notaOpen.cod_nfe, "numero", "", this.notaOpen.sistema).subscribe((data:any)=>{        
          this.notaOpenMSG = data.Status + " - " + data.Mensagem;        
      });
    }
  }

  openNFE(nf){
    this.notaOpen = nf; 
    this.notaOpenMSG = '';
    this.newStatus = '';
    this.newStatus2 = null;

  }

  carregaNFEOPEN(){
    
  }

  changeSTS(ev){
    this.newStatus = ev.target.value;
    console.log(this.newStatus);
  }
  trocarStatusNF(){
    if(confirm("Tem certeza? Certeza?\nC E R T E Z A ? ?")){      
      this.notasSrv.postNotas(this.notaOpen.cod_nfe, "status", this.newStatus, this.notaOpen.sistema).subscribe((data:any)=>{        
          this.notaOpenMSG = data.Status + " - " + data.Mensagem;   
        this.newStatus = null;
        this.newStatus2 = null;
      });
    }
  }

  ngOnInit() {
  }

}
