import { ChamadosService } from './../chamados.service';
import { MonitoramentoService } from './../monitoramento.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { NotasfiscaisService } from '../notasfiscais.service';
import { NgSwitchCase } from '@angular/common';
import { usuarioLogado } from '../app.component';
import { Router } from '@angular/router';



@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})


export class DashComponent implements OnInit {

  usuario = usuarioLogado;



  constructor(public router: Router) {

    if (usuarioLogado.cod_usuario <= 0) {
      router.navigate(['home']);
    }

    //this.buscarMonitoramento();
  }





  ngOnInit() {

  }

}
