import { LoginService } from './../login.service';
import { Component, OnInit } from '@angular/core';
import { usuarioLogado } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public loginsrc: LoginService, public router: Router) { }

  logado;
  usuar = usuarioLogado;
  ngOnInit() {
    let user = localStorage.getItem('user');
    const us = JSON.parse(user)[0];
    console.log('VERIFICA USU', user, us);
    if (user && user != '' && us) {
      this.logado = true;
      usuarioLogado.cod_usuario = us.cod_usuario;
      usuarioLogado.nome = us.nome;
      usuarioLogado.master = us.master;
      if (usuarioLogado.cod_usuario == 341 || usuarioLogado.cod_usuario == 1000026) {
        usuarioLogado.master = false;
      }
      usuarioLogado.adm_chamado = us.adm_chamado;
      console.log('usu', usuarioLogado);
      this.router.navigate(["dashboard"]);
    }
  }

  submiot(ev) {
    if (ev.usuario === '') {
      ev.usuario = '0';
    }

    if (ev.senha === '') {
      ev.senha = '0';
    }
    this.loginsrc.login(ev.usuario, ev.senha).subscribe((data: Array<any>) => {
      console.log(data);
      let user = localStorage.getItem('user');
      if (!user) {
        localStorage.removeItem('user');
      }
      localStorage.setItem('user', JSON.stringify(data));
      console.log(localStorage.getItem('user'));

      this.logado = data != null && data.length > 0;
      if (data != null && data.length > 0) {
        usuarioLogado.cod_usuario = data[0].cod_usuario;
        usuarioLogado.nome = data[0].nome;
        usuarioLogado.master = data[0].master;
        if (usuarioLogado.cod_usuario == 341 || usuarioLogado.cod_usuario == 1000026) {
          usuarioLogado.master = false;
        }
        usuarioLogado.adm_chamado = data[0].adm_chamado;
        console.log(usuarioLogado);
        this.router.navigate(["dashboard"]);
      }
    });

  }

}
