import { usuarioLogado, Usuario } from './app.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url = "https://api.dashboard.opservidor.com/api/login";
  //url = "http://localhost:5415/api/login";
  
  constructor(public httpSrc:HttpClient) { }

  login(usu,sen){
    return this.httpSrc.post(this.url, {usuario:usu, senha:sen});
  }
}
